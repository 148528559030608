<template>
        <!-- Edit item details (form) -->
      <b-container class="mt-4">
        <b-row align-h="center">
          <b-col cols="12" sm="8" md="6" lg="5">
            <div class="box-default rounded mb-3">

              <!-- Select item notice (if no item is selected) -->
              <h5 
                v-if="selectedItem == null || selectedItem.isRoot" 
                class="text-center my-2">
                {{$t('view.me.itemDetailsAlert')}}</h5>

              <div v-else>

                <!-- NAME -->
                <b-row align-h="start" align-v="center" class="my-1">
                  <b-col cols="3" offset="1">
                    <label for="name">
                      {{$t('view.me.itemName')}}:
                    </label>
                  </b-col>
                  <b-col cols="7">
                    <b-form-input
                      :value="selectedItem.name"
                      @change="updateItemName"
                      class="mt-1"
                      id="name" 
                      :placeholder="$t('view.me.namePlaceholder')"
                      type="text">
                    </b-form-input>
                  </b-col>
                </b-row>

                <!-- DESCRIPTION (if file) -->
                <b-row v-show="selectedItem.isFile" align-h="start" align-v="center" class="my-1">
                  <b-col cols="3" offset="1">
                    <label for="description">
                      {{$t('view.me.itemDescription')}}:
                    </label>
                  </b-col>
                  <b-col cols="7">
                    <b-form-textarea
                      :value="selectedItem.description"
                      @change="updateItemDescription"
                      class="mt-1"
                      id="description"
                      max-rows="4"
                      :placeholder="$t('view.me.descriptionPlaceholder')">
                    </b-form-textarea>
                  </b-col>
                </b-row>

                <!-- VISIBILITY -->
                <b-row align-h="start" align-v="center" class="my-1">
                  <b-col cols="3" offset="1">
                    <label for="visibility">
                      {{$t('view.me.itemVisibility')}}:
                    </label>
                  </b-col>
                  <b-col cols="7">
                    <b-form-radio-group
                      :checked="selectedItem.visibility"
                      @change="updateItemVisibility"
                      class="mt-1"
                      id="visibility"
                      name="visibility">
                      <b-form-radio 
                        :value="selectedItem.isFolder ? $getConst('PUBLIC_FOLDER') : $getConst('PUBLIC_FILE')">
                        {{$t('view.me.itemPublic')}}
                      </b-form-radio>
                      <b-form-radio 
                        :value="selectedItem.isFolder ? $getConst('PRIVATE_FOLDER') : $getConst('PRIVATE_FILE')">
                        {{$t('view.me.itemPrivate')}}
                      </b-form-radio>
                      <b-form-radio 
                        v-show="selectedItem.isFolder"
                        :value="$getConst('SHARED_FOLDER')">
                        {{$t('view.me.itemShared')}}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>


                <b-row align-h="center" align-v="center" class="my-1">
                  <b-col cols="10" class="d-flex justify-content-center">

                    <b-input-group 
                      class="my-2" 
                      v-show="selectedItem.isFolder && selectedItem.visibility == $getConst('SHARED_FOLDER')">
                        <b-form-input
                          v-model="selectedItem.share_code"
                          readonly
                          class=""
                          id="share-link"
                          type="text">
                        </b-form-input>
                      <b-input-group-append>
                        <b-button 
                          @click="copyShareLink"
                          class="border"
                          :disabled="!selectedItem.isFolder || selectedItem.visibility != $getConst('SHARED_FOLDER')" 
                          variant="secondary" 
                          size="sm">
                          {{$t('view.me.copy')}} <b-icon shift-h="1" icon="clipboard"></b-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <!-- GO TO CARDS PAGE -->
                    <b-button 
                      v-show="selectedItem.isFile"
                      @click="gotoModifyCards"
                      class="mx-1 my-2" 
                      variant="warning" 
                      >{{$t('view.me.editCards')}}
                    </b-button>


                  </b-col>
                </b-row>

              </div>
            </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  methods: {

    // vuex will pass in context and payload -> we have to construct the object payload
    updateItemName(data) {
      var dataType = this.$getConst('ITEM_NAME');

      if (this.selectedItem.isFolder) this.updateFolder({dataType: dataType, data: data});
      else if (this.selectedItem.isFile) this.updateFile({dataType: dataType, data: data});
      
    },

    updateItemVisibility(data) {
      var dataType = this.$getConst('ITEM_VISIBILITY');

      if (this.selectedItem.isFolder) this.updateFolder({dataType: dataType, data: data});
      else if (this.selectedItem.isFile) this.updateFile({dataType: dataType, data: data});
    },

    updateItemDescription(data) {
      var dataType = this.$getConst('ITEM_DESCRIPTION');

      this.updateFile({dataType: dataType, data: data});
    },

    copyShareLink() {
      /* Get the text field */
      var copyText = document.getElementById("share-link");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      alert("Copied the text: " + copyText.value);
    },
    ...mapActions([
      'updateFolder',
      'updateFile',
      'gotoModifyCards',
    ])
  },
  computed: {
    ...mapState ({
        selectedItem: state => state.userdata.selectedItem,
      }),
  },
}
</script>