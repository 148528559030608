<template>
  <div>

    <Navbar
    ></Navbar> 

    <GenericHeader class="mb-2" :title="$t('view.me.header')"></GenericHeader>
    <ModifyDataTree></ModifyDataTree>
    <ModifyTreeButtons></ModifyTreeButtons>


    <ModifyCards v-if="isModifyCardsMode"></ModifyCards>
    <ModifyItemDetails v-else></ModifyItemDetails>

  </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import ModifyDataTree from '@/components/ModifyDataTree.vue'
import ModifyTreeButtons from '@/components/ModifyTreeButtons.vue'
import ModifyItemDetails from '@/components/ModifyItemDetails.vue'
import ModifyCards from '@/components/ModifyCards.vue'
import GenericHeader from '@/components/GenericHeader.vue'

import { mapState, mapActions } from 'vuex'

export default {
  title() {
    return this.$t('view.me.name')
  },
  components: {
    Navbar,
    ModifyDataTree,
    ModifyTreeButtons,
    ModifyItemDetails,
    ModifyCards,
    GenericHeader,
  },
  methods: {
    ...mapActions([
      'getUserData',
    ]),
  },
  computed: {
    ...mapState({
      isDarkTheme: (state) => state.settings.isDarkTheme,
      isModifyCardsMode: (state) => state.userdata.isModifyCardsMode,
    }),
  },
  beforeRouteEnter (to, from, next) {
    console.log('beforeRouteEnter fired my files page');
    next( vm => {
      vm.getUserData();
    });
  }
}
</script>