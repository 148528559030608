<template>
  <div>
    <b-container>
      <b-row align-h="center"> 
        <b-col cols="12" sm="8" md="6" lg="5">
          <b-row no-gutters align-h="end">

            <!-- New [📁] (parent can't be a file)-->
            <b-col class="ml-1" cols="auto">
              <b-button
                class="file-tree-button"
                :disabled="selectedItem == null || selectedItem.isFile"
                @click="addFolder"
                size="sm">
                  {{ $t('view.me.new') + ' 📁' }} 
              </b-button>
            </b-col>

            <!-- New [📄] (parent can't be a file)-->
            <b-col class="ml-1" cols="auto">
              <b-button
                class="file-tree-button"
                :disabled="selectedItem == null || selectedItem.isFile"
                size="sm" 
                @click="addFile"
                >
                  {{ $t('view.me.new') + ' 📄' }} 
              </b-button>
            </b-col>

            <!-- Delete [📁, 📄] (item has to be a folder or file)-->
            <b-col class="ml-1" cols="auto">
              <b-button 
              class="file-tree-button"
              :disabled="selectedItem == null || selectedItem.isRoot == true"
              size="sm" 
              @click="selectedItem.isFolder ? deleteFolder() : deleteFile()"
              >
              {{ $t('view.me.delete')+ ' ❌'}}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

  export default {
    methods: {
      addFolder() {
        var newFolder = {
          "name": (this.$t('view.me.newFolder') + ` ${this.selectedItem.folders.length + 1}`),
          "parent_folder": this.selectedItem.id,
          "visibility": 1,
        };

        // assure that the name is unique 
        this.selectedItem.folders.forEach(folder => {
          if (newFolder.name == folder.name) {
            newFolder.name = folder.name + 1;
          }
        })
        this.createFolder(newFolder);
      },

      addFile() {
        var newFile = {
          "name": (this.$t('view.me.newFile') + ` ${this.selectedItem.files.length + 1}`),
          "folder": this.selectedItem.id,
        };
        // assure that the name is unique 
        this.selectedItem.files.forEach(file => {
          if (newFile.name == file.name) {
            newFile.name = file.name + 1;
          }
        })
        this.createFile(newFile);
      },

      ...mapActions([
        'createFolder',
        'createFile',
        'deleteFolder',
        'deleteFile',
      ]),
    },
    computed: {
      ...mapState ({
          selectedItem: state => state.userdata.selectedItem,
        }),
    },
  }
</script>