<template>
	<b-container>
		<b-row class="mt-3" align-h="center">
      <b-col sm="10" md="8" lg="6" cols="12">
        <b-card
          :bg-variant="isDarkTheme ? 'dark' : 'light'"
          :text-variant="isDarkTheme ? 'light' : 'dark'"
          :border-variant="isDarkTheme ? '' : 'secondary'"
          footer-border-variant=""
          class="text-center modify-card d-flex flex-grow-1 flex-sm-grow-0"
          no-body>

          <b-card-body class="d-flex align-items-center justify-content-center">
            
            <b-icon
              @click="gotoPreviousCard"
              class="mr-2"
              :style="(getCurrentCard && (currentCardIndex > 0)) ? {} : {visibility: 'hidden'}"
              icon="chevron-double-left">
            </b-icon>

            <div style="max-height:7rem; overflow:auto;" class="flex-grow-1" :class="!getCurrentCard ? ['text-danger', 'font-weight-bold'] : []">
              <b-form-textarea
                v-if="isEditCardMode && getCurrentCard"
                :value="getCurrentCard == null ? $t('view.me.noCards') : getCurrentCard.content"
                @change="updateCardContent"
                id="card-content"
                max-rows="3">
              </b-form-textarea>
              <div v-else class="text-break">
                {{getCurrentCard == null ? $t('view.me.noCards') : getCurrentCard.content}}
              </div>
            </div>

            <b-icon
              class="ml-2"
              @click="gotoNextCard"
              :style="(getCurrentCard && (currentCardIndex < (selectedItem.cards.length-1))) ? {} : {visibility:'hidden'}"
              icon="chevron-double-right">
            </b-icon>

          </b-card-body>

          <template #header>
            <div class="d-flex justify-content-start align-items-center">

              <div class="flex-grow-1 text-left">
                <b-icon 
                @click="gotoModifyItem" 
                class="back-icon" 
                icon="arrow-left" 
                font-scale="1.2">
                </b-icon>
              </div>

              <div class="text-right d-flex align-items-center">
                <b-button
                  class="file-tree-button mx-1"
                  @click="addCard"
                  size="sm">
                    {{ $t('view.me.newFemale') }} 
                    <b-icon
                    class="mx-1"
                    variant="success"
                    icon="card-text">
                    </b-icon>
                </b-button>

                <b-button
                  v-show="getCurrentCard"
                  class="file-tree-button mx-1"
                  size="sm"
                  @click="deleteCardItem">
                  {{$t('view.me.delete')+ ' ❌'}}
                </b-button>

                <b-button
                  v-show="getCurrentCard"
                  @click="isEditCardMode = !isEditCardMode"
                  class="file-tree-button mx-1"
                  size="sm">
                    {{ isEditCardMode ? $t('view.me.save') : $t('view.me.edit')}}
                    <b-icon
                    :variant="isEditCardMode ? 'success' : ''"
                    :icon="isEditCardMode ? 'check2' : 'pencil'">
                    </b-icon>
                </b-button>
                
              </div>

            </div>
          </template>

          <template v-if="getCurrentCard" #footer>

            <div class="d-flex justify-content-end text-right align-items-center">
              <b-button
              class="file-tree-button mx-1"
              v-show="getCurrentCard"
              size="sm"
              @click="addHint"
              >
                {{ $t('view.me.new') }} 
                <b-icon
                variant="success"
                icon="question-diamond">
                </b-icon>
              </b-button>

              <b-button
              class="file-tree-button mx-1"
              v-show="getCurrentHint"
              @click="deleteHintItem"
              size="sm"
              >
                {{$t('view.me.delete')+ ' ❌'}} 
              </b-button>

              <b-button
              class="file-tree-button mx-1"
              v-show="getCurrentHint"
              @click="isEditHintMode = !isEditHintMode"
              size="sm"
              >
                {{ isEditHintMode ? $t('view.me.save') : $t('view.me.edit')}}
                <b-icon
                :variant="isEditHintMode ? 'success' : ''"
                :icon="isEditHintMode ? 'check2' : 'pencil'">
                </b-icon>
              </b-button>
            </div>
            
            <div class="d-flex align-items-center justify-content-center">

              <b-icon
                class="mr-2"
                @click="gotoPreviousHint"
                :style="(getCurrentHint && (currentHintIndex > 0)) ? {} : {visibility: 'hidden'}"
                icon="chevron-double-left">
              </b-icon>

              <div class="my-3 flex-grow-1" :class="!getCurrentHint ? ['text-danger', 'font-weight-bold'] : []">
                <b-form-input
                v-if="isEditHintMode && getCurrentHint"
                :value="getCurrentHint == null ? $t('view.me.noHints') : getCurrentHint.content"
                @change="updateHintContent"
                class=""
                id="hint-content"
                type="text">
                </b-form-input>
                <div v-else class="card-hint">
                  <em>{{ getCurrentHint == null? $t('view.me.noHints') : getCurrentHint.content }}</em>
                </div>
              </div>

              <b-icon
                class="ml-2"
                @click="gotoNextHint"
                :style="(getCurrentHint && (currentHintIndex < (getCurrentCard.hints.length-1))) ? {} : {visibility:'hidden'}"
                icon="chevron-double-right">
              </b-icon>           

            </div>
          </template>
        </b-card>
      </b-col>
		</b-row>
	</b-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
	data() {
		return {
      isEditCardMode: false,
      isEditHintMode: false,
		};
	},
	methods: {
    deleteCardItem() {
      // this is to assure that if the last available card is deleted, the user
      // can't modify the "no cards available alert"
      this.deleteCard();
    },
    deleteHintItem() {
      this.deleteHint();
    },
    addCard() {
      var newCard = {
        content: this.$t('view.me.newCard') + ' ' + (this.selectedItem.cards.length + 1),
        file: this.selectedItem.id,
      };

      // assure that the name is unique 
      this.selectedItem.cards.forEach(card => {
        if (newCard.content == card.content) {
          newCard.content = card.content + '1';
        }
      });
      this.createCard(newCard);
    },
    addHint() {
      var newHint = {
        content: this.$t('view.me.newHint') + ' ' + (this.getCurrentCard.hints.length + 1),
        card: this.getCurrentCard.id,
      };

      // assure that the name is unique 
      this.getCurrentCard.hints.forEach(hint => {
        if (newHint.content == hint.content) {
          newHint.content = hint.content + '1';
        }
      });
      this.createHint(newHint);
    },
    updateCardContent(value) {
      this.isEditCardMode = false;
      this.updateCard({content: value});
    },
    updateHintContent(value) {
      this.isEditHintMode = false;
      this.updateHint({content: value});
    },
		...mapActions([
      'gotoModifyItem',
      'createCard',
      'deleteCard',
      'updateCard',
      'createHint',
      'deleteHint',
      'updateHint',
      'gotoNextCard',
      'gotoPreviousCard',
      'gotoNextHint',
      'gotoPreviousHint',
    ]),
	},

	computed: {
		...mapState({
			isDarkTheme: (state) => state.settings.isDarkTheme,
      selectedItem: state => state.userdata.selectedItem,
      currentCardIndex: state => state.userdata.currentCardIndex,
      currentHintIndex: state => state.userdata.currentHintIndex,
		}),
		...mapGetters([
      "getCurrentCard",
      "getCurrentHint",
      ]),
	},
};
</script>
