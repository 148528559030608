<template>
  <div>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" sm="8" md="6" lg="5">
          <div class="my-tree rounded mb-2">
            <Treeselect
              :autoLoadRootOptions="false"
              :value="selectedItem"
              value-format="object"
              @input="updateSelectedItem"
              value-consists-of="LEAF_PRIORITY"
              :flatten-search-results="true"
              :autoSelectDescendants="false"
              :autoDeselectDescendants="false"
              :options="UserData" 
              :normalizer="normalizer"
              :limit="2"
              always-open
              :max-height="300"
              :searchable="true"
              :multiple="false"
              :defaultExpandLevel="Infinity"
              >

              <!-- bug? I can only drag when I click the left AND right mouse button -->
              <label
                draggable="true"
                slot="option-label" 
                :class="labelClassName"
                slot-scope="{ node, labelClassName }">
                {{node.label}}
              </label>

            </Treeselect>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      Treeselect,
    },
    methods: {
    ...mapActions([
      'updateSelectedItem',
    ]),

      normalizer(node) {
        //console.log('node passed into normalizer function:',node)
        if (node == undefined || node == null || node.id == null) {
          return node;
        }
        else {
          var normalized_node = {};
  
          // check if root
          if (node.isRoot === true) {
            //console.log('Found root!')
  
            normalized_node.id = this.$getConst('ROOT') + node.id;
            normalized_node.label = this.$t('view.me.root');
            normalized_node.children = [...node.files, ...node.folders];
          }
  
          // check if folder
          else if (node.cards == undefined) {
            //console.log('Found folder!')
  
            normalized_node.id = this.$getConst('FOLDER') + node.id;
  
            normalized_node.label = '📁 ' + node.name;
  
            // folder could contain files and/or sub-folders 
            normalized_node.children = [...node.files, ...node.folders];
  
            // explictly mutate node thats passed in
            node.isFolder = true;
          }
  
          // check if file
          else if (node.folders == undefined) {
            //console.log('Found file!')
  
            normalized_node.id = this.$getConst('FILE') + node.id;
  
            normalized_node.label = '📄 ' + node.name;
  
            // explicitly mutate node thats passed in
            node.isFile = true;
  
            /* NOTE: a file shouldn't display its cards 
            by not assigning a children array, vue-treeselect treats this
            node as a leaf */ 
  
          }
          else {
            console.log('Normalizer error: didnt find root, folder, or file')
          }
          return normalized_node;
        }
      }
    },
    computed: {
      ...mapState ({
          UserData: state => state.userdata.UserData,
          selectedItem: state => state.userdata.selectedItem,
        }),
    },
  }
</script>
